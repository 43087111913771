import {Label} from '@radix-ui/react-label'
import * as RadioGroup from '@radix-ui/react-radio-group'
import type {ActionFunctionArgs, MetaFunction} from '@remix-run/node'
import {redirect} from '@remix-run/node'
import {useActionData} from '@remix-run/react'
import {ValidatedForm} from '@rvf/remix'
import {withZod} from '@rvf/zod'
import {z} from 'zod'
import {SubmitButton} from '~/components/SubmitButton'
import {useParentLoaderData} from '~/hooks/useParentLoaderData'
import {setCookieConsent} from '~/utils/cookies.server'
import {parseFormData} from '~/utils/forms.server'

export const validator = withZod(
  z.object({
    allowed: z.enum(['yes', 'no']),
    redirect: z.optional(z.literal('redirect')),
  }),
)

export const meta: MetaFunction = () => {
  return [
    {title: 'Cookies | Depot'},
    {
      name: 'description',
      content: 'Depot is a remote container build service that can build Docker images up to 40x faster',
    },
    {property: 'og:image', content: 'https://depot.dev/depot-social.png'},
    {property: 'twitter:image', content: 'https://depot.dev/depot-social.png'},
    {property: 'twitter:card', content: 'summary_large_image'},
  ]
}

export async function action({request}: ActionFunctionArgs) {
  const {data} = await parseFormData(validator, await request.formData())
  const cookiesAllowed = data.allowed === 'yes'
  console.log(request.headers.get('referrer') ?? '/cookies')
  return redirect(request.headers.get('Referer') ?? '/cookies', {
    headers: {'Set-Cookie': await setCookieConsent(cookiesAllowed)},
  })
}

export default function Page() {
  const data = useActionData<typeof action | {cookiesAllowed?: boolean}>()
  const parentData = useParentLoaderData('root')
  const allowed = data ? data.cookiesAllowed : parentData?.cookiesAllowed

  return (
    <div className="prose prose-mauve mx-4 mb-8 max-w-prose md:prose-lg md:mx-auto md:mt-16">
      <h1>Cookies</h1>

      <p>
        Cookies are small files that are stored on your computer when you visit a website. We use cookies to make Depot
        work and to understand how we can make it better.
      </p>

      <h2>Essential cookies</h2>

      <p>
        Essential cookies are cookies that are required for Depot to function properly. We do not need to ask permission
        to use them. We use the following essential cookies:
      </p>

      <table>
        <thead>
          <tr>
            <th className="w-1/5">Name</th>
            <th>Purpose</th>
            <th className="w-1/5">Expiration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code className="whitespace-nowrap">depot-session</code>
            </td>
            <td>Used to store your user session and keep you logged in.</td>
            <td>
              <code>session</code>
            </td>
          </tr>
          <tr>
            <td>
              <code className="whitespace-nowrap">cookie-consent</code>
            </td>
            <td>Saves your cookie consent settings.</td>
            <td>
              <code>1 year</code>
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Analytics cookies (optional)</h2>

      <p>
        With your permission, we use <a href="https://posthog.com">PostHog</a> to collect data about how you use Depot.
        This information helps us improve our service.
      </p>

      <table>
        <thead>
          <tr>
            <th className="w-1/5">Name</th>
            <th>Purpose</th>
            <th className="w-1/5">Expiration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code className="whitespace-nowrap">ph_xxxxxxxx</code>
            </td>
            <td>
              Identifies your user session in PostHog, allows PostHog to construct a timeline of events (e.g. clicked
              link, visited page).
            </td>
            <td>
              <code>session</code>
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Do you want to accept analytics cookies?</h2>

      <ValidatedForm method="post" validator={validator}>
        <input type="hidden" name="redirect" value="redirect" />
        <RadioGroup.Root name="allowed" defaultValue={allowed === undefined ? undefined : allowed ? 'yes' : 'no'}>
          <div className="space-y-8">
            <div className="flex gap-8">
              <div className="inline-flex items-center gap-2">
                <RadioGroup.Item
                  value="yes"
                  id="cookies-yes"
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-radix-mauve12"
                >
                  <RadioGroup.Indicator className="h-3 w-3 rounded-full bg-radix-mauve1" />
                </RadioGroup.Item>
                <Label className="cursor-pointer" htmlFor="cookies-yes">
                  Yes
                </Label>
              </div>
              <div className="inline-flex items-center gap-2">
                <RadioGroup.Item
                  value="no"
                  id="cookies-no"
                  className="flex h-6 w-6 items-center justify-center rounded-full bg-radix-mauve12"
                >
                  <RadioGroup.Indicator className="h-3 w-3 rounded-full bg-radix-mauve1" />
                </RadioGroup.Item>
                <Label className="cursor-pointer" htmlFor="cookies-no">
                  No
                </Label>
              </div>
            </div>
            <SubmitButton label="Save cookie settings" submittingLabel="Saving cookie settings..." />
          </div>
        </RadioGroup.Root>
      </ValidatedForm>
    </div>
  )
}
